import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom"
import App from "../layout/App";
import { useStore } from "../stores/store";
import Dashboard from "../pages/Dashboard";
import PhotoGallery from "../pages/PhotoGallery";
import LoginForm from "../pages/LoginForm";
import ServerError from "../utils/errors/ServerError";
import DrawDetails from "../pages/DrawDetails";
import ListDraws from "../pages/ListDraws";
import GameBoard from "../pages/GameBoard";
import DrawParticipants from "../pages/DrawParticipants";
import Statistics from "../pages/Statistics";
import DrawPrizes from "../pages/DrawPrizes";
import { basename } from "../utils/env";

function PrivateRoute({ children }: { children: JSX.Element }) {
  const { userStore } = useStore();
  return userStore.isLoggedIn ? children : <Navigate to="/login" />;
}

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {path: '', element: <PrivateRoute><Dashboard /></PrivateRoute>} ,
      {path: 'draws', element: <PrivateRoute><ListDraws /></PrivateRoute>},
      {path: 'draws/:id', element: <PrivateRoute><DrawDetails key='manage' /></PrivateRoute>},
      {path: 'draws/new', element: <PrivateRoute><DrawDetails key='create' /></PrivateRoute>},
      {path: 'draw-participants/:id', element: <PrivateRoute><DrawParticipants key='manage' /></PrivateRoute>},
      {path: 'draw-prizes/:id', element: <PrivateRoute><DrawPrizes key='manage' /></PrivateRoute>},
      {path: 'photo-gallery', element: <PrivateRoute><PhotoGallery /></PrivateRoute>},
      {path: 'statistics/:id', element: <PrivateRoute><Statistics /></PrivateRoute>},
      {path: 'game/:id', element: <GameBoard />},
      {path: 'login', element: <LoginForm />},
      {path: 'errors', element: <PrivateRoute><h1>Error</h1></PrivateRoute>},
      {path: 'server-error', element: <PrivateRoute><ServerError /></PrivateRoute>},
    ]
  },

];

export const router = createBrowserRouter(
  routes,
  {
    basename: basename
  }
);