import { useState } from "react";
import CenteredCard from "../layout/CenteredCard";
import AdminField from "../layout/AdminField";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { toast } from "react-toastify";
import { useStore } from "../stores/store";
import { observer } from "mobx-react-lite";

export default observer(function LoginForm() {
  const {userStore} = useStore();
  const [errors, setErrors] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formValues, setFormValues] = useState({
    email: '',
    password: ''
  });

  const validateForm = () => {
    let tempErrors: any = {};

    if (!formValues.email) tempErrors.email = "Lütfen e-postanızı giriniz";
    if (!formValues.password) tempErrors.password = "Lütfen parolanızı giriniz";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!validateForm()) {
      toast.error(
        "Lütfen geri dönüp kırmızıyla işaretlenen zorunlu alanları doldurunuz!"
      );
      return;
    }

    setIsSubmitting(true);

    try {
      await userStore.login(formValues);
      toast.success("Giriş başarılı!");
    } catch (error) {
      setErrors({form: "Geçersiz e-posta veya parola"});
      toast.error("Giriş başarısız!")
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CenteredCard login>      
      <form className="login-form" onSubmit={handleSubmit}>
        <Typography variant="h5" sx={{ mb: 4 }}>
          Giriş Yapınız
        </Typography>
        <Grid container spacing={4} sx={{ width: "100%" }}>
          <AdminField
            label="E-Posta"
            type="email"
            value={formValues.email}
            onChange={(value) => setFormValues({ ...formValues, email: value })}
            error={!!errors.email}
            errorMessage={errors.email}
            isLogin
            fullWidth
          />
          <AdminField
            label="Parola"
            type="password"
            value={formValues.password}
            onChange={(value) => setFormValues({ ...formValues, password: value })}
            error={!!errors.password}
            errorMessage={errors.password}
            isLogin
            fullWidth
          />
        </Grid>
        {errors.form && (
          <Typography color="error" sx={{marginBottom: 2}}>{errors.form}</Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ my: 4 }}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Giriş Yapılıyor..." : "Giriş Yap"}
        </Button>
      </form>
    </CenteredCard>
  );
});