import { Card, CardContent } from "@mui/material";

interface CenteredCardProps {
  children?: React.ReactNode;
  maxWidth?: string;
  login?: boolean;
}

export default function CenteredCard({ children, maxWidth= "auto", login }: CenteredCardProps) {
  return (
    <div className={`full-screen-centered ${login && "with-bg"}`}>
      {login && (
        <img
          className="login-logo"
          src={`${process.env.PUBLIC_URL}/assets/hediye-cekilis-logo.png`}
          width="336"
          height="80"
          alt="Hediye Çekiliş Yazılımı"
          style={{marginBottom: 20}}
        />
      )}
      <Card sx={{minWidth: '40%', maxWidth: maxWidth}}>
        <CardContent>{children}</CardContent>
      </Card>
    </div>
  );
}
