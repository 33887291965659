import axios, { AxiosError, AxiosResponse } from 'axios';
import { env } from '../utils/env';
import { User, UserFormValues } from '../models/user';
import { toast } from 'react-toastify';
import { store } from '../stores/store';
import { GalleryPhoto } from '../models/galleryPhoto';
import { Draw } from '../models/draw';
import { Prize } from '../models/prize';
import { GameDto } from '../models/gameDto';
import { DashboardData } from '../models/dashboard';
import { StatisticsDto } from '../models/statisticsDto';
import { Game } from '../models/game';
import { Participant } from '../models/participant';

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

axios.defaults.baseURL = env + "/api";

axios.interceptors.request.use(config => {
  const token = store.commonStore.token;
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  
  // Set Content-Type for non-GET requests
  if (config.method !== 'get' && config.headers && !config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json';
  }

  return config;
});

axios.interceptors.response.use(
  response => response,
  (error) => {
      if (error.response) {
          console.error("API Error Response:", error.response.data);
      } else {
          console.error("API Error (No Response):", error.message);
      }
      return Promise.reject(error);
  }
);

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T> (url: string) => axios.get<T>(url).then(responseBody),
  post: <T> (url: string, body?: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T> (url: string) => axios.post<T>(url).then(responseBody),
  upload: <T>(url: string, formData: FormData) => axios.post<T>(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const Draws = {
  list: () => requests.get<Draw[]>('/draws'),
  details: (id: string) => requests.get<Draw>(`/draws/${id}`),
  create: (draw: any) => requests.post<void>('/draws', draw),
  update: (draw: any) => requests.post<void>(`/draws/${draw.id}`, draw),
  delete: (id: string) => requests.del<void>(`/draws/deleteDraw/${id}`),
  listOrder: (id: string, direction: string) => requests.post<void>('/draws/updateListOrder', {id: id, direction: direction}),
  listLight: () => requests.get<{ id: string, title: string }[]>('/draws'),
  listParticipants: (drawId: string) => requests.get<Participant[]>(`/draws/listParticipants/${drawId}`),
  createParticipant: (participant: any) => requests.post<void>('/draws/createParticipant', participant),
  bulkParticipants: (participants: Participant[]) => requests.post<void>('/draws/bulkParticipants', participants),
  deleteParticipant: (participantId: string) => requests.del<void>(`/draws/deleteParticipant/${participantId}`),
  deleteAllParticipants: (drawId: string) => requests.del<void>(`/draws/deleteAllParticipants/${drawId}`),
  getDrawData: (id: string) => requests.get<{ screenType: number, drawCount: number }>(`/draws/getDrawData/${id}`)
}

const Prizes = {
  listOrder: (id: string, drawId: string, direction: string) => requests.post<void>('/prizes/updateListOrder', {id: id, drawId: drawId, direction: direction}),
  listOfDraw: (drawId: string) => requests.get<Prize[]>(`/prizes/getDrawPrizes/${drawId}`),
  update: (prize: Prize) => requests.post<void>(`/prizes/${prize.id}`, prize),
  create: (prize: Prize) => requests.post<void>('/prizes', prize),
  bulkPrizes: (drawId: string, prizes: Prize[]) => requests.post<void>(`/prizes/bulkPrizes/${drawId}`, prizes),
  delete: (id: string) => requests.del<void>(`/prizes/deletePrize/${id}`),
  deleteAll: (drawId: any) => requests.del<void>(`prizes/deleteAll/${drawId}`),
}

const GalleryPhotos = {
  list: () => requests.get<GalleryPhoto[]>('/galleryPhotos'),
  create: (formData: FormData) => requests.upload<void>('/galleryPhotos', formData),
  delete: (id: string) => requests.del<void>(`/galleryPhotos/deleteGalleryPhoto/${id}`),
}

const Account = {
  current: () => requests.get<User>('account'),
  login: (user: UserFormValues) => requests.post<User>('account/login', user),
  register: (user: UserFormValues) => requests.post<User>('account/register', user)
}

const Games = {
  list: () => requests.get<GameDto[]>('/games'),
  listOfDraw: (drawId: string) => requests.get<GameDto[]>(`/games/getDrawGames/${drawId}`),
  create: (game: Game) => requests.post<void>('/games', game),
  getDashboard: () => requests.get<DashboardData>('/games/getDashboard'),
  statistics: (drawId: string) => requests.get<StatisticsDto[]>(`/games/getStatistics/${drawId}`),
  deleteAll: (drawId: any) => requests.del<void>(`games/deleteAll/${drawId}`),
};

const Participants = {  
  create: (participant: Participant) => requests.post<void>('/participants', participant),
  update: (participant: Participant) => requests.post<void>(`/participants/${participant.id}`, participant),
  deleteAll: (drawId: any) => requests.del<void>(`participants/deleteAll/${drawId}`),
};

const agent = {
  Draws,
  Prizes,
  GalleryPhotos,
  Account,
  Games,
  Participants
}

export default agent;