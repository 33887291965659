import { useEffect, useState } from "react";
import { Participant } from "../models/participant";
import { Draw } from "../models/draw";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { v4 as uuid } from "uuid";
import * as XLSX from "xlsx";
import agent from "../api/agent";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { DataGrid, GridArrowDownwardIcon, GridArrowUpwardIcon, GridColDef } from "@mui/x-data-grid";
import { Alert, Box, Button, Chip, FormControlLabel, IconButton, Modal, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from "@mui/icons-material/Download";
import AdminLayout from "../layout/AdminLayout";
import { Prize } from "../models/prize";
import { env } from "../utils/env";
import ImagePickerModal from "../layout/ImagePickerModal";

interface ExcelRow {
  PrizeValue: string;
  PrizeImage?: string;
  PlatformImage?: string;
  ListOrder: number;
}

export default function DrawPrizes() {
  const { id } = useParams<{ id: string }>();
  const [drawData, setDrawData] = useState<{ screenType: number | null, drawCount: number | null }>({
    screenType: 0,
    drawCount: 0
  });
  const [prizes, setPrizes] = useState<Prize[]>([]);
  const [editingPrizeId, setEditingPrizeId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  
  const initialFormValues: any = {
    prizeValue: "",
    prizeImage: "",
    platformImage: ""
  };
  const [openModal, setOpenModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [activeField, setActiveField] = useState<string | null>(null);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errors, setErrors] = useState<any>({});
  const [fileInputKey, setFileInputKey] = useState(0);

  const fetchPrizes = async () => {
    setLoading(true);
    if (id) {
      try {
        const response = await agent.Prizes.listOfDraw(id);
        const fetchedPrizes = response;
        setPrizes(fetchedPrizes);
        setLoading(false);        
      } catch (error) {
        setLoading(false);
        toast.error('Ödüller getirilemedi.');
      }
    }
  };

  useEffect(() => {
    const fetchDrawData = async () => {
      if (id) {
        try {
          const response = await agent.Draws.getDrawData(id);
          setDrawData(response);
        } catch (error) {
          console.error("Failed to fetch screen type:", error);
          setDrawData({ screenType: null, drawCount: null });
        }
      }
    };
  
    fetchDrawData();
  }, [id]);

  useEffect(() => {
    fetchPrizes();
  }, [id]);

  useEffect(() => {
    if (location.state?.reload) {
      fetchPrizes();
    }
  }, [location.state]);

  const handleOpenModal = (prize?: Prize) => {
    if (prize) {
      setEditingPrizeId(prize.id);
      setFormValues({
        prizeValue: prize.prizeValue,
        prizeImage: prize.prizeImage,
        platformImage: prize.platformImage,
        listOrder: prize.listOrder
      });
    } else {
      setEditingPrizeId(null);
      setFormValues({
        prizeValue: "",
        prizeImage: "",
        platformImage: "",
        listOrder: (drawData.drawCount || 0) + 1
      });
    }
    setOpenModal(true);
  };

  const movePrize = async (prizeId: string, direction: "up" | "down") => {
    if (!prizes || prizes.length < 2) return;

    const prizeIndex = prizes.findIndex((p) => p.id === prizeId);
    if (prizeIndex === -1) return;

    const targetIndex = direction === "up" ? prizeIndex - 1 : prizeIndex + 1;
    if (targetIndex < 0 || targetIndex >= prizes.length) return;

    const updatedPrizes = [...prizes];

    [updatedPrizes[prizeIndex].listOrder, updatedPrizes[targetIndex].listOrder] = 
    [updatedPrizes[targetIndex].listOrder, updatedPrizes[prizeIndex].listOrder];

    setPrizes([...updatedPrizes.sort((a, b) => a.listOrder - b.listOrder)]);

    try {
      await agent.Prizes.listOrder(prizeId, id!, direction);
    } catch (error) {
      toast.error("Sıralama güncellenirken hata oluştu");
    }
  };

  const handleDeletePrize = (id: string) => {
    const deletedPrize = prizes.find(prize => prize.id === id);
    if (!deletedPrize) return;
    
    Swal.fire({
        title: 'Silmek istediğinizden emin misiniz?',
        text: 'Silme işlemini geri alamazsınız!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Tamam',
        cancelButtonText: 'İptal',
    }).then((result) => {
        if (result.isConfirmed) {
          agent.Prizes.delete(id).then(() => {
            setPrizes(prev => 
              prev
                .filter(prize => prize.id !== id)
                .map(prize => 
                  prize.listOrder > deletedPrize.listOrder 
                    ? { ...prize, listOrder: prize.listOrder - 1 } 
                    : prize
                )
            );
            setDrawData(prev => ({
              ...prev,
              drawCount: Math.max(0, (prev.drawCount || 0) - 1)
            }));
            toast.success('Ödül silindi!');
          }).catch((error) => {
              toast.error('Ödül silinirken bir hata oluştu.');
          });
        }
    });
  };

  const handleDeleteAllPrizes = () => {
    Swal.fire({
      title: "Silmek istediğinizden emin misiniz?",
      text: "Bu çekiliş için kaydedilmiş tüm ödül verileri silinecektir. Silme işlemini geri alamazsınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Tamam",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (id && result.isConfirmed) {
        agent.Prizes.deleteAll(id)
          .then(() => {
            setPrizes([]);
            setDrawData(prev => ({
              ...prev,
              drawCount: 0
            }));
            toast.success("Tüm ödül verileri silindi!");
          })
          .catch((error) => {
            toast.error("Veriler silinirken bir hata oluştu.");
          });
      }
    });
  };

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const arrayBuffer = e.target?.result as ArrayBuffer;
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const rows: (string | undefined)[][] = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
      });

      let listOrderStart = (drawData.drawCount || 0) + 1;
  
      // Transform data into prizes
      const prizes = rows.filter((row) => row[0]).map((row, index) => ({
        id: uuid(),
        prizeValue: row[0] || "",
        platformImage: row[1] || "",
        prizeImage: row[2]?.toString() || "",
        listOrder: listOrderStart + index,
        drawId: id || ""
      }));
  
      if (prizes.length === 0) {
        toast.error(
          "Excel dosyasında Ödül Adı alanı olan ilk kolon boş bırakılamaz!"
        );
      } else {
        uploadPrizes(prizes);
      }
      setFileInputKey(prevKey => prevKey + 1);
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadPrizes = async (prizes: Prize[]) => {
    try {
      if (id) {
        await agent.Prizes.bulkPrizes(id, prizes);
      }
      setPrizes((prev) => [...prev, ...prizes]);
      setDrawData(prev => ({
        ...prev,
        drawCount: (prev.drawCount || 0) + prizes.length
      }));
      toast.success("Ödüller başarıyla eklendi");
    } catch (error) {
      toast.error("Ödüller eklenirken bir hata oluştu");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: name === 'listOrder' ? parseInt(value) : value
    });
  };

  const handleFieldChange = (field: string, value: any) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const validateForm = () => {
    let tempErrors: any = {};

    if (!formValues.prizeValue) tempErrors.prizeValue = "Ödül Adı zorunludur";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error(
        "Lütfen geri dönüp kırmızıyla işaretlenen zorunlu alanları doldurunuz!"
      );
      return;
    }

    const newListOrder = formValues.listOrder ? Number(formValues.listOrder) : (drawData.drawCount || 0) + 1;
    let oldListOrder = 0;    
    if (editingPrizeId) {
      const existingPrize = prizes.find(p => p.id === editingPrizeId);
      if (existingPrize) {
        oldListOrder = existingPrize.listOrder;
      }
    }

    const prizeData = {
      id: editingPrizeId ?? uuid(),
      prizeValue: formValues.prizeValue,
      prizeImage: formValues.prizeImage,
      platformImage: formValues.platformImage,
      listOrder: newListOrder,
      drawId: id || ""
    }

    try {
      if (editingPrizeId) {
        await agent.Prizes.update(prizeData);

        setPrizes(prevPrizes => {
          let updated = prevPrizes.map(p =>
            p.id === editingPrizeId ? { ...p, ...prizeData } : p
          );
  
          if (oldListOrder !== undefined && oldListOrder !== newListOrder) {
            updated = updated.map(p => {
              if (p.id === prizeData.id) {
                return p; 
              }
  
              if (oldListOrder < newListOrder) {
                if (p.listOrder > oldListOrder && p.listOrder <= newListOrder) {
                  return { ...p, listOrder: p.listOrder - 1 };
                }
              }
              else {
                if (p.listOrder >= newListOrder && p.listOrder < oldListOrder) {
                  return { ...p, listOrder: p.listOrder + 1 };
                }
              }
  
              return p;
            });
          }
  
          updated.sort((a,b) => a.listOrder - b.listOrder);
  
          return updated;
        });
        
        toast.success("Ödül başarıyla güncellendi");
      } else {
        await agent.Prizes.create(prizeData);
        setPrizes([...prizes, prizeData]);
        setDrawData(prev => ({
          ...prev,
          drawCount: (prev.drawCount || 0) + 1
        }));
        toast.success("Ödül başarıyla eklendi");
      }
    } catch (error) {
      toast.error(editingPrizeId ? "Ödül güncellenirken hata oluştu" : "Ödül eklenirken hata oluştu");
    }

    handleClose();
  };

  const handleClose = () => {    
    setOpenModal(false);
    setEditingPrizeId(null);
    setFormValues({
      prizeValue: "",
      prizeImage: "",
      platformImage: "",
      listOrder: ""
    });
  };

  const openImagePicker = (field: string) => {
    setActiveField(field);
    setOpenImageModal(true);
  };

  const handleImageSelect = (url: string) => {
    if (!prizes || !activeField) return;
    handleFieldChange(activeField, url);
    setOpenImageModal(false);
  };

  const columns: GridColDef[] = [
    {
      field: "listOrder",
      headerName: "Sıra",
      width: 40,
    },
    {
      field: "order",
      headerName: "Sıralama",
      width: 90,
      renderCell: (params) => (
        <>
          <Grid>
            <IconButton
              aria-label="move prize up"
              onClick={() => movePrize(params.row.id, "up")}
              disabled={params.row.listOrder === 1}
            >
              <GridArrowUpwardIcon />
            </IconButton>
            <IconButton
              aria-label="move prize down"
              onClick={() => movePrize(params.row.id, "down")}
              disabled={params.row.listOrder === drawData.drawCount}
            >
              <GridArrowDownwardIcon />
            </IconButton>
          </Grid>
        </>
      )
    },
    {
      field: "prizeValue",
      headerName: "Ödül Adı",
      width: 200,
    },
    {
      field: "platformImage",
      headerName: "Platform Görseli",
      width: 300,
    },
    {
      field: "prizeImage",
      headerName: "Ödül Görseli",
      width: 300,
    },
    {
      field: "actions",
      headerName: "Eylemler",
      width: 360,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => handleOpenModal(params.row)}
            style={{marginRight: "15px"}}
          >
            Düzenle
          </Button>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={() => handleDeletePrize(params.row.id)}
          >
            Sil
          </Button>
      </>
      ),
    },
  ];

    if (drawData.screenType !== null && drawData.screenType !== 1) {      
      return (
        <AdminLayout title="Ödüller">
          <Alert sx={{ my: 3 }} severity="warning">
            <strong>Ekran tipi ödül ekranına uygun değil!</strong><br />
            Ödülleri bu ekrandan kontrol edebilmek için çekilişin ekran tipi Klasik olmalıdır.
          </Alert>
          <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(`/draws/${id}`)}
              sx={{ mb: 2, mr: 3 }}
            >
              Çekilişe Dön
            </Button>
        </AdminLayout>
      )
    }

  return (
    <>
      <AdminLayout title="Ödüller">
        <Grid
          container
          spacing={2}
          marginBottom={2}
          sx={{ justifyContent: "space-between" }}
        >
          <Grid size={{xs: 6, sm: 6, md: 6}}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(`/draws/${id}`)}
              sx={{ mb: 2, mr: 3 }}
            >
              Çekilişe Dön
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenModal(true)}
              sx={{ mb: 2, mr: 3 }}
            >
              Ödül Ekle
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteAllPrizes}
              sx={{ mb: 2 }}
            >
              Tüm Ödülleri Sil
            </Button>
          </Grid>
          <Grid size={{xs: 6, sm: 6, md: 4}}>
            <input
              key={fileInputKey}
              type="file"
              accept=".xlsx"
              style={{ display: "none" }}
              id="file-upload"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) handleFileUpload(file);
              }}
            />
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                startIcon={<UploadIcon />}
                color="success"
                component="span"
              >
                Excel Dosya Gönder
              </Button>
            </label>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          marginBottom={2}
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Grid size={{xs: 9, sm: 9, md: 8}}>
            <Alert sx={{ my: 3 }} severity="info">
              Toplu ödül eklemek için Sağ üstteki <strong>Excel Dosya Gönder</strong>{" "}
              butonunu kullanabilirsiniz. Sadece <strong>xlsx</strong> formatında{" "}
              dosya ekleyebilirsiniz.
            </Alert>
          </Grid>
          <Grid size={{xs: 3, sm: 3, md: 4}}>
            <Button
              variant="contained"
              size="small"
              color="info"
              onClick={() => setOpenInfoModal(true)}
              sx={{ mb: 2, mr: 3 }}
            >
              Dosya Yükleme Kılavuzu
            </Button>
          </Grid>
        </Grid>
        
        <DataGrid
          rows={prizes}
          columns={columns}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          pageSizeOptions={[50, 100, 200]}
          checkboxSelection
        />
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className="game-modal modal"
        >
          <Box
            sx={{
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              outline: "none",
            }}
          >
            <p>{editingPrizeId ? "Ödülü Düzenle" : "Ödül Ekle"}</p>
            <form className="login-form" onSubmit={handleSubmit}>
              <TextField
                label="Ödül Adı"
                name="prizeValue"
                type="text"
                fullWidth
                variant="outlined"
                value={formValues.prizeValue}
                onChange={handleInputChange}
                required
                helperText={errors.prizeValue}
                error={!!errors.prizeValue}
              />

              <Grid size={2} sx={{ display: 'flex', alignItems: 'flex-start', gap: '15px', mt: 3 }}>
                <img
                  className="placeholder-img"
                  src={formValues.platformImage ? env + formValues.platformImage : `${process.env.PUBLIC_URL}/assets/placeholder-1.jpg`}
                  height="60"
                  width="60"
                  alt="Selected"
                />
                <TextField
                  label="Ödül Görseli"
                  name="platformImage"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValues.platformImage}
                  onChange={handleInputChange}
                  sx={{ marginRight: '10px' }}
                />
              </Grid>
              <Button variant="contained" color="primary" onClick={() => openImagePicker("platformImage")} sx={{ mb: 5 }}>
                Galeri'den Seç
              </Button>

              <Grid size={2} sx={{ display: 'flex', alignItems: 'flex-start', gap: '15px', mt: 3 }}>
                <img
                  className="placeholder-img"
                  src={formValues.prizeImage ? env + formValues.prizeImage : `${process.env.PUBLIC_URL}/assets/placeholder-1.jpg`}
                  height="60"
                  width="60"
                  alt="Selected"
                />
                <TextField
                  label="Popup Görseli"
                  name="prizeImage"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValues.prizeImage}
                  onChange={handleInputChange}
                  sx={{ marginRight: '10px' }}
                />
              </Grid>
              <Button variant="contained" color="primary" onClick={() => openImagePicker("prizeImage")} sx={{ mb: 5 }}>
                Galeri'den Seç
              </Button>
              
              {editingPrizeId && (
                <TextField
                  select
                  label="Sıralama"
                  name="listOrder"
                  fullWidth
                  variant="outlined"
                  value={formValues.listOrder}
                  onChange={handleInputChange}
                  SelectProps={{ native: true }}
                >
                  {Array.from({ length: drawData.drawCount || 0 }, (_, i) => (
                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                  ))}
                </TextField>
              )}
              <Button
                variant="contained"
                type="submit"
                sx={{ my: 4, backgroundColor: "#FAB406" }}
              >
                {editingPrizeId ? "Güncelle" : "Ekle"}
              </Button>
            </form>
          </Box>
        </Modal>
        <Modal
          open={openInfoModal}
          onClose={() => setOpenInfoModal(false)}
          aria-labelledby="modal-info"
          aria-describedby="modal-file-upload-info"
          className="game-modal modal"
        >
          <Box
            sx={{
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              outline: "none",
            }}
          >
            <Alert sx={{ my: 3 }} severity="info">
              Toplu ödül eklemek için Sağ üstteki <strong>Excel Dosya Gönder</strong>{" "}
              butonunu kullanabilirsiniz. Sadece <strong>xlsx</strong> formatında{" "}
              dosya ekleyebilirsiniz. <br /><br />
              
              <strong>İkinci kolon {"(B)"}:</strong> Platform Görseli <br />
              <strong>Üçüncü kolon {"(C)"}:</strong> Ödül Görseli <br /><br />
              
              Sadece ilk kolonu doldurmanız yeterlidir. Eğer diğer bilgilerin de yer almasını{" "}
              istiyorsanız youkarıdaki sıraya göre kolonları doldurabilirsiniz. İlk kolon dışında{" "}
              diğerlerini istediğiniz sırayla boş bırakabilirsiniz, bazılarında doldurmayabilir{" "}
              ya da tümünde doldurabilirsiniz. Ancak ilk kolonu boş olan satırlar kaydedilmeyecektir.<br /><br />

              Ödül ekleme formatı için <strong>Örnek Excel Dosyası</strong>nı aşağıdan indirebilirsiniz;<br /><br />

              <Button
                variant="contained"
                size="small"
                color="warning"
                component="a"
                href={`${process.env.PUBLIC_URL}/assets/ornek-toplu-odul-excel.xlsx`}
                download
                startIcon={<DownloadIcon />}
              >
                Örnek Dosya İndir
              </Button>
            </Alert>
          </Box>
        </Modal>
        <ImagePickerModal
          open={openImageModal}
          onClose={() => setOpenImageModal(false)}
          onSelect={handleImageSelect}
        />
      </AdminLayout>
    </>
  );
}